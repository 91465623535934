<template>
    <div
        class="w-bookmakers-card"
        :style="cardStyle"
    >
        <div class="w-bookmakers-card__header">
            <template v-if="isPartner">
                <UiTooltip
                    v-if="isRu"
                    class="w-bookmakers-card__partner"
                >
                    <IconPartner />

                    <template #drop>
                        {{ $t('disclaimer') }}
                    </template>
                </UiTooltip>

                <IconPartner
                    v-else
                    class="w-bookmakers-card__partner"
                />
            </template>
            <img
                :src="logo"
                :alt="webname"
                class="w-bookmakers-card__logo"
            >
        </div>

        <div class="w-bookmakers-card__info">
            <UiStarRating
                read-only
                :rating="ratingScore"
                :star-size="16"
                :increment="0.1"
                :padding="5"
                :border-width="0"
                active-color="#FFF"
                inactive-color="#BAC3C6"
            />

            <span class="w-bookmakers-card__rating-score">
                {{ ratingScore.toFixed(1) }}
            </span>

            <div
                v-if="reviewScore"
                class="w-bookmakers-card__reviews"
                @click="goToReviews"
            >
                <IconComment />

                <span class="w-bookmakers-card__reviews-count">
                    {{ reviewScore }}
                </span>
            </div>
        </div>

        <div :class="dividerClasses" />

        <div :class="footerClasses">
            <WBookmakersCardBonuses
                v-if="hasBonuses"
                :bonuses="bonuses"
                class="w-bookmakers-card__bonuses"
                @click-bonus="sendEventBonus"
                @scroll-bonus="sendEventScrollBonus"
            />

            <router-link
                :to="reviewRoute"
                class="w-bookmakers-card__review-btn"
                @click.native="sendEventLink"
            >
                {{ $t('review') }}
            </router-link>

            <a
                :href="siteLink"
                class="w-bookmakers-card__link-btn"
                rel="nofollow"
                target="_blank"
                @click="sendEventSite"
            >
                {{ $t('site-link') }}
            </a>
        </div>
        <span
            v-if="marker"
            class="bi-subtitle-3"
            :style="{ display: 'flex', justifyContent: 'flex-start', marginTop: '5px', color: '#fff' }"
        >
            {{ marker }}
        </span>
        <RefreshableDfp
            :placement="`bookmakers-rating__${webname}--fake`"
            @receive="bookmakersRatingCreativeReceived"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "review": "Обзор",
        "site-link": "Перейти на сайт",
        "disclaimer": "Партнеры Betting Insider размещены на коммерческой основе"
    },
    "en": {
        "review": "Review",
        "site-link": "Site",
        "disclaimer": ""
    }
}
</i18n>

<script>
import WBookmakersCardBonuses from '@/components/Widgets/Bookmakers/WBookmakersCardBonuses.vue'
import IconPartner from '@/assets/svg/partner.icon.svg'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'

import isValidHex from '@/utils/hex'

const DEFAULT_BG_COLOR = '#1F1F1F'

export default {
    name: 'WBookmakersCard',

    components: {
        IconPartner,
        RefreshableDfp,
        WBookmakersCardBonuses
    },

    props: {
        index: {
            type: Number,
            default: 0
        },

        webname: {
            type: String,
            default: ''
        },

        color: {
            type: String,
            default: ''
        },

        isPartner: {
            type: Boolean,
            default: false
        },

        logo: {
            type: String,
            default: ''
        },

        ratingScore: {
            type: Number,
            default: 0
        },

        reviewScore: {
            type: Number,
            default: 0
        },

        bonuses: {
            type: Array,
            default: () => []
        },

        websites: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            marker: ''
        }
    },

    computed: {
        cardStyle () {
            const primaryColor = isValidHex(this.color) ? this.color : DEFAULT_BG_COLOR

            return {
                backgroundColor: primaryColor
            }
        },

        siteLink () {
            const site = this.websites.find(item => item.type === 'MAIN')

            if (site) {
                return site.url
            }

            return null
        },

        reviewRoute () {
            return {
                name: 'bookmaker',
                params: {
                    webname: this.webname
                }
            }
        },

        hasBonuses () {
            return !!this.bonuses.length
        },

        dividerClasses () {
            return {
                'w-bookmakers-card__divider': true,
                'w-bookmakers-card__divider--invisible': this.hasBonuses
            }
        },

        footerClasses () {
            return {
                'w-bookmakers-card__footer': true,
                'w-bookmakers-card__footer--bonuses': this.hasBonuses
            }
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        }
    },

    methods: {
        goToReviews () {
            this.$pivik.event(
                'bookmakers_widget',
                'click_reviews',
                this.index,
                this.webname
            )

            this.$router.push({
                name: 'bookmaker-reviews',
                params: {
                    webname: this.webname
                }
            })
        },

        bookmakersRatingCreativeReceived (message) {
            this.marker = message.marker
        },

        sendEventLink () {
            this.$pivik.event(
                'bookmakers_widget',
                'click_bookmaker_page',
                this.index,
                this.webname
            )
        },

        sendEventSite () {
            this.$pivik.event(
                'bookmakers_widget',
                'click_site',
                this.index,
                this.webname
            )
        },

        sendEventBonus (bonusIndex) {
            this.$pivik.event(
                'bookmakers_widget',
                'click_bonus',
                bonusIndex,
                this.index
            )
        },

        sendEventScrollBonus (scrollType) {
            this.$pivik.event(
                'bookmakers_widget',
                'scroll_bonus',
                scrollType,
                this.index
            )
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-bookmakers-card
    box-sizing border-box
    height 100%
    padding 2rem 1.1rem 1.2rem
    display flex
    flex-direction column
    align-items center
    color #FFF
    border-radius $radius-block
    box-shadow 0 0 1rem rgba(0, 0, 0, .08)
    width 27.6rem
    background-image linear-gradient(90deg, rgba(255, 255, 255, .09) 0%, rgba(0, 0, 0, .10) 57%)

    &__header
        display flex
        flex-direction row
        align-items center

    &__partner
        margin-right 1rem

    &__logo
        width 14rem
        height 4rem
        object-fit contain

    &__info
        margin-top 1.4rem
        display flex
        flex-direction row
        align-items center
        font-size 1.4rem
        font-weight $font-weight-bold

    &__rating-score
        margin-left .9rem

    &__reviews
        display flex
        align-items center
        cursor pointer

        &::before
            content '|'
            margin 0 2rem
            color rgba(@color, .3)
            font-size 1.7rem
            font-weight $font-weight-regular
            font-family sans-serif
            cursor default

        &-count
            margin-left .9rem

    &__divider
        margin-top 2.2rem
        flex 1
        width 100%
        opacity 0
        border-bottom 1px solid rgba(@color, .1)

        &--invisible
            opacity 1

    &__footer
        margin-top 1.4rem
        width 100%
        display grid
        grid-template-columns 1fr
        grid-template-rows auto auto
        grid-gap 1.6rem .8rem

        &--bonuses
            grid-template-columns 8.1rem 1fr

    &__bonuses
        grid-column 1 / 3

    &__review-btn
        button(5, 'outlined', 'white')

    &__link-btn
        button(5, 'contained', 'white')

</style>
