<template>
    <div
        v-if="hasWidget"
        class="w-bookmakers"
    >
        <div class="w-bookmakers__header">
            <h2 class="bi-header-4 w-bookmakers__title">
                {{ title }}
                <AdDisclaimer
                    v-if="isRu"
                    :text="$t('disclaimer')"
                    class="w-bookmakers__disclaimer"
                />
            </h2>

            <router-link
                :to="bookmakersRoute"
                class="w-bookmakers__all-link link-text-inner bi-button"
                @click.native="sendEventAll"
            >
                {{ $t('all') }}
            </router-link>
        </div>

        <WBookmakersCarousel
            :list="bookmakers"
            class="w-bookmakers__carousel"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "best-bookmakers": "Лучшие букмекеры",
        "other-bookmakers": "Другие букмекеры",
        "all": "Все",
        "disclaimer": "Партнеры Betting Insider размещены на коммерческой основе"
    },
    "en": {
        "best-bookmakers": "Best bookmakers",
        "other-bookmakers": "Other bookmakers",
        "all": "All",
        "disclaimer": ""
    }
}
</i18n>

<script>
import { mapGetters, mapState } from 'vuex'
import WBookmakersCarousel from '@/components/Widgets/Bookmakers/WBookmakersCarousel.vue'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'


export default {
    name: 'WBookmakers',

    components: {
        AdDisclaimer,
        WBookmakersCarousel
    },

    props: {
        excludeName: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            isMounted: false,
            widgetsObserver: null
        }
    },

    computed: {
        ...mapState('bookmakersRating', ['geo']),
        ...mapGetters('widgets/bookmakers', ['getBookmakers']),

        title () {
            return this.excludeName ? this.$t('other-bookmakers') : this.$t('best-bookmakers')
        },

        bookmakers () {
            return this.getBookmakers(this.excludeName)
        },

        hasWidget () {
            return !!this.bookmakers.length
        },

        bookmakersRoute () {
            return {
                name: 'bookmakers-default'
            }
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        }

        // canFetchBookmakersList () {
        //     return this.geo
        // }
    },

    watch: {
        hasWidget (newVal) {
            if (newVal && !this.widgetsObserver) {
                this.widgetsObserver = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.$pivik.event(
                                'bookmakers_widget',
                                'view'
                            )
                        }
                    })
                })

                this.$nextTick(() => {
                    // eslint-disable-next-line no-unused-expressions
                    this.hasWidget && this.widgetsObserver.observe(this.$el)
                })
            }
        }
    },

    mounted () {
        this.isMounted = true
    },

    methods: {
        sendEventAll () {
            this.$pivik.event(
                'bookmakers_widget',
                'click_all'
            )
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-bookmakers
    margin-bottom 4rem

    &__header
        display flex
        justify-content space-between
        align-items flex-end
        margin-bottom 1.6rem

    &__title
        margin 0

        +layout-xxs()
            width 19rem

    &__all-link
        line-height 2.2rem

    &__disclaimer
        margin-top -.3rem

    &__carousel
        +layout-sm()
            margin 0 (-($l-column-horizontal-padding-sm))

        +layout-xs()
            margin 0 (-($l-column-horizontal-padding-xs))

</style>
