<template>
    <button
        class="w-bookmakers-card-bonuses-item"
        @click="clickBonus"
    >
        <span class="w-bookmakers-card-bonuses-item__amount">
            {{ bonusAmount | number }} {{ bonusCurrency }}
        </span>

        <span class="w-bookmakers-card-bonuses-item__type">{{ name }}</span>
    </button>
</template>

<script>
import currencyCodeToSymbol from '@/utils/currency-code-to-symbol'
import number from '@/filters/number'

export default {
    name: 'WBookmakersCardBonusesItem',

    filters: {
        number
    },

    props: {
        index: {
            type: Number,
            default: 0
        },

        type: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            default: ''
        },

        link: {
            type: String,
            default: ''
        },

        amount: {
            type: Number,
            default: 0
        },

        currencyCode: {
            type: String,
            default: ''
        },

        percent: {
            type: Number,
            default: 0
        }
    },

    computed: {
        isPercentType () {
            return this.type === 'PERCENT'
        },

        bonusAmount () {
            if (this.isPercentType) {
                return this.percent
            }

            return this.amount
        },

        bonusCurrency () {
            if (this.isPercentType) {
                return '%'
            }

            return currencyCodeToSymbol(this.currencyCode)
        }
    },

    methods: {
        clickBonus () {
            this.$emit('click-bonus', this.index)

            window.open(this.link, '_blank')
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-bookmakers-card-bonuses-item
    width calc(100% - 1px)
    padding .6rem
    display flex
    flex-direction column
    align-items center
    font-family $font-family-content
    border 1px solid rgba(#FFF, .5)
    border-radius $radius-block
    color currentColor
    background initial
    outline none
    text-decoration none
    cursor pointer
    user-select none

    &__amount
        font-size 2.4rem
        line-height @font-size
        font-weight $font-weight-bold
        overflow hidden
        text-overflow ellipsis

    &__type
        margin-top .2rem
        font-size 1rem
        line-height @font-size
        color rgba(#FFF, .5)
        overflow hidden
        text-overflow ellipsis

</style>
