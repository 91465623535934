<template>
    <div>
        <BiPageHeader>
            <h1 class="bi-flex bi-header-4">
                {{ $t('title') }}
            </h1>
        </BiPageHeader>

        <div
            v-if="isAuth"
            class="view-account-settings"
        >
            <div
                v-if="account.type === 'email_password'"
                class="bi-block bi-block__body"
            >
                <BiSettingsEmail
                    :email="account.id"
                    :confirmed="account.confirmed"
                    @request="sendConfirmEmail"
                />
            </div>
        </div>
        <div v-if="checked && !isAuth">
            {{ $t('permission') }}
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "title": "Настройки",
        "permission": "Нет доступа"
    },
    "en": {
        "title": "Settings",
        "permission": "No access"
    }
}
</i18n>

<script>
import { mapState, mapGetters } from 'vuex'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import BiSettingsEmail from '@/components/common/bi-settings-email/index.vue'

export default {
    name: 'AccountSettingsView',

    components: {
        BiPageHeader,
        BiSettingsEmail
    },

    data () {
        return {
            pendingSendConfirm: false
        }
    },

    computed: {
        ...mapState('auth', ['checked', 'isAuth', 'account']),
        ...mapGetters('profile', ['my'])
    },

    methods: {
        sendConfirmEmail (complete) {
            this.$store.dispatch('auth/sendConfirmEmail').then(() => {
                complete()
                this.$root.$emit('popup-confirm-email-success')
            })
                .catch(() => {
                    complete()
                })
        }
    }
}
</script>
