<template>
    <div
        v-if="hasCarousel"
        class="w-bookmakers-carousel"
    >
        <UiSwiper
            :slides="list"
            :options="swiperOptions"
            @click-left="onClickLeft"
            @click-right="onClickRight"
        >
            <template
                #default="{ item, index }"
            >
                <WBookmakersCard
                    class="w-bookmakers-carousel__card"
                    :index="index + 1"
                    :webname="item.webname"
                    :color="item.primaryColor"
                    :is-partner="item.isPartner"
                    :logo="item.logo"
                    :rating-score="item.biRatings"
                    :review-score="item.statistics.reviewCount"
                    :bonuses="item.bonuses"
                    :websites="item.websites"
                />
            </template>
        </UiSwiper>

        <button class="w-bookmakers-carousel__button w-bookmakers-carousel__button-prev">
            <IconArrowLeft class="arrow-icon" />
        </button>

        <button class="w-bookmakers-carousel__button w-bookmakers-carousel__button-next">
            <IconArrowLeft class="arrow-icon" />
        </button>
    </div>
</template>

<script>
import WBookmakersCard from '@/components/Widgets/Bookmakers/WBookmakersCard.vue'


export default {
    name: 'WBookmakersCarousel',

    components: {
        WBookmakersCard
    },

    props: {
        list: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            swiperProgress: 0,
            swiperOptions: {
                slidesPerView: 'auto',
                slidesPerGroup: 1,

                navigation: {
                    prevEl: '.w-bookmakers-carousel__button-prev',
                    nextEl: '.w-bookmakers-carousel__button-next',
                    disabledClass: 'w-bookmakers-carousel__button--disabled'
                },

                breakpoints: {
                    640: {
                        slidesPerGroup: 2
                    }
                }
            }
        }
    },

    computed: {
        hasCarousel () {
            return !!this.list.length
        }
    },

    methods: {
        onClickLeft () {
            this.$pivik.event(
                'bookmakers_widget',
                'scroll_widget',
                'click_left'
            )
        },

        onClickRight () {
            this.$pivik.event(
                'bookmakers_widget',
                'scroll_widget',
                'click_right'
            )
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-bookmakers-carousel
    position relative

    &__card
        margin-right 1.2rem

    &__button
        position absolute
        top 50%
        z-index 10
        transform translateY(-50%)

        width 3.2rem
        height @width
        display flex
        justify-content center
        align-items center
        background-color $cl-white
        border .1rem solid $cl-secondary-light
        border-radius 100rem
        color $cl-text-dark
        cursor pointer
        outline none

        &:hover .arrow-icon
            opacity .6

        +layout-sm()
            display none

    &__button-prev
        left -1rem

    &__button-next
        right -1rem

        & .arrow-icon
            transform rotate(180deg)

    &__button--disabled
        display none

>>>.swiper-container:not(.swiper-container .swiper-container)
    +layout-sm()
        padding 0 $l-column-horizontal-padding-sm

    +layout-xs()
        padding 0 $l-column-horizontal-padding-xs


>>>.swiper-slide:not(.swiper-slide .swiper-slide)
    height auto
    width 27.6rem

    &:not(:last-child)
        margin-right $l-column-horizontal-padding-xs

</style>
