<template>
    <div class="bi-block view-account__wrap view-account">
        <h1 class="bi-header-4 view-account__title">
            {{ $t('t-title') }}
        </h1>
        <p class="t-center view-account__desc">
            {{ $t('t-desc') }}
        </p>

        <form @submit.prevent="submit()">
            <PasswordInput
                v-model="password"
                :placeholder="$t('t-pass')"
                name="password"
                autocomplete="password"
            />

            <input
                v-model="confirmPassword"
                :placeholder="$t('t-confirm')"
                type="password"
                class="view-account__confirm"
            >

            <div
                v-if="error"
                class="view-account__error bi-subtitle-1"
            >
                {{ error }}
            </div>

            <button
                class="view-account__submit view-account__submit--stretch"
                type="submit"
                :pending="pending"
            >
                {{ $t('t-submit') }}
            </button>
        </form>
    </div>
</template>

<i18n>
{
    "ru": {
        "t-title": "Создание нового пароля",
        "t-desc": "Осталось ввести новый пароль и вы продолжите играть и выигрывать.",
        "t-pass": "Введите новый пароль",
        "t-confirm": "Подтвердите новый пароль",
        "t-submit": "Отправить"
    },
    "en": {
        "t-title": "Create new password",
        "t-desc": "Enter new password to continue playing and winning",
        "t-pass": "Enter new password",
        "t-confirm": "Confirm new password",
        "t-submit": "Send"
    }
}
</i18n>

<script>
import PasswordInput from '@/components/common/PasswordInput/PasswordInput.vue'
import ErrorMessage from '@/mixins/error-message'

export default {
    name: 'AccountCreatePasswordView',

    components: {
        PasswordInput
    },

    mixins: [ErrorMessage],

    data () {
        return {
            pending: false,
            error: '',
            password: '',
            confirmPassword: ''
        }
    },

    methods: {
        validate () {
            if (this.password.length < 6) {
                this.error = this.getErrorMessageByCode('invalid_short_password')

                return false
            }

            if (this.password !== this.confirmPassword) {
                this.error = this.getErrorMessageByCode('invalid_confirm_password')

                return false
            }

            this.error = ''

            return true
        },

        async submit () {
            if (!this.validate()) {
                return
            }

            this.pending = true

            try {
                await this.$store.dispatch('auth/createPassword', {
                    loc: this.$i18n.locale,
                    token: this.$route.params.token,
                    password: this.password
                })

                this.$router.push({ name: 'profile', params: { id: this.$store.state.auth.userId } })
            } catch (e) {
                this.error = this.getErrorMessageByResponse(e)
            }

            this.pending = false
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" src="@/views/account/style.styl"></style>

<style lang="stylus" scoped>
.view-account
    &__confirm
        input()
        width 100%
        margin-top .8rem

</style>>
