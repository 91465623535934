<template>
    <div class="email-confirmed">
        <WTeaser :matches="teaser" />

        <div class="view-account bi-block email-confirmed__congratulations">
            <h1 class="bi-header-4">
                <span v-if="pending">{{ $t('t-confirm') }}</span>
                <span v-if="success">{{ $t('t-confirmed') }}</span>
                <span v-if="error">{{ errorMessage }}</span>
            </h1>

            <p
                v-if="error"
                class="t-center view-account__desc"
            >
                {{ $t('t-desc') }}
            </p>
            <p
                v-if="success"
                class="t-center view-account__desc"
            >
                {{ $t('t-text1') }} <span class="t-bold">{{ email }}</span> {{ $t('t-text2') }}
            </p>

            <button
                v-if="error && isAuth"
                class="email-confirmed__submit-button email-confirmed__submit-button--stretch"
                @click="onSettings"
            >
                {{ $t('t-settings') }}
            </button>
            <button
                v-if="success"
                class="email-confirmed__submit-button email-confirmed__submit-button--stretch"
                @click="onGame"
            >
                {{ $t('t-play') }}
            </button>
        </div>

        <noindex v-if="recommendations.length">
            <BiPageHeader>
                <h2 class="bi-header-4 email-confirmed__predictions-title">
                    {{ $t('predictions-title') }}
                </h2>
                <span>
                    {{ $t('predictions-subtitle') }}
                </span>
            </BiPageHeader>

            <PredictionsRecommendations :items="recommendations" />
        </noindex>
    </div>
</template>

<i18n>
{
    "ru": {
        "t-confirm": "Подтверждение e-mail",
        "t-confirmed": "E-mail подтвержден",
        "t-desc": "Повторите запрос подтверждения в настройках профиля!",
        "t-settings": "Настройки",
        "t-text1": "Ваш e-mail",
        "t-text2": "подтвержден. Играйте и выигрывайте!",
        "t-play": "Начать игру",
        "predictions-title": "Лучшие прогнозы на сегодня",
        "predictions-subtitle": "Выберите коэффициент и повторите прогноз в один клик"
    },
    "en": {
        "t-confirm": "Email confirmation",
        "t-confirmed": "Email confirmed",
        "t-desc": "Request another confirmation in profile settings",
        "t-settings": "Settings",
        "t-text1": "Your email",
        "t-text2": "has been confirmed. Play and win!",
        "t-play": "Let's start",
        "predictions-title": "Best tips",
        "predictions-subtitle": "Select the odds and repeat the tip in one click"
    }
}
</i18n>

<script>
import ErrorMessage from '@/mixins/error-message'
import { mapState, mapGetters } from 'vuex'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import PredictionsRecommendations from '@/components/Predictions/Recommendations/PredictionsRecommendations.vue'
import WTeaser from '@/components/Widgets/Teaser/WTeaser.vue'

export default {
    name: 'ConfirmEmailView',
    components: {
        WTeaser,
        BiPageHeader,
        PredictionsRecommendations
    },

    mixins: [ErrorMessage],

    asyncData ({ store }) {
        return Promise.all([
            store.dispatch('predictions/recommendations/fetch'),
            WTeaser.loadMatches(store, { hour_range: 48 })
        ])
            .catch(() => {})
    },

    data () {
        return {
            success: false,
            error: false,
            pending: true
        }
    },

    computed: {
        ...mapGetters('predictions/recommendations', ['recommendations']),
        ...mapState('auth', ['checked', 'isAuth', 'account']),
        ...mapState('widgets/teaser', ['teaser']),

        email () {
            if (this.account.type === 'email_password' && this.account.id) {
                return this.account.id
            }

            return ''
        },

        errorMessage () {
            return this.getErrorMessageByCode('default')
        }
    },

    mounted () {
        this.confirm()
    },

    methods: {
        confirm () {
            this.$store.dispatch('auth/confirmEmail', {
                token: this.$route.params.token
            })
                .then(() => {
                    this.pending = false
                    this.success = true
                    this.error = false
                })
                .catch(() => {
                    this.pending = false
                    this.success = false
                    this.error = true
                })
        },

        onSettings () {
            this.$router.push({ name: 'account-settings' })
        },

        onGame () {
            this.$router.push({ name: 'predictions', params: { sport: 'football' } })
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus" src="@/views/account/style.styl"></style>

<style lang="stylus" scoped>
.email-confirmed
    &__congratulations
        padding 2.4rem
        margin-bottom 2.8rem
        margin-top 2rem

    &__submit-button
        button(5, 'contained', 'primary')
        max-width 28.8rem

    &__predictions-title
        margin-bottom .8rem

</style>
