const CURRENCY_CODE_MAP = {
    RUB: '₽',
    USD: '$',
    EUR: '€',
    KZT: '₸',
    UAH: '₴'
}

/**
 * Функция для получения символа валюты по коду
 *
 * @param {String} value - код валюты
 *
 * @returns {String} символ валюты
 */
export default function currencyCodeToSymbol (value) {
    return CURRENCY_CODE_MAP[value] || value
}
