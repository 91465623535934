<template>
    <div class="bi-settings-email bi-subtitle-1">
        <div class="bi-settings-email__label">
            <span
                v-if="!confirmed"
                class="t-color-warn"
            >{{ $t('notconfirmed') }}</span>
            <span v-else>E-mail</span>
        </div>
        <input
            :value="email"
            disabled
            type="text"
            class="bi-settings-email__input"
        >

        <div class="bi-layout">
            <a
                v-if="!confirmed"
                @click="onRequest"
            >{{ $t('resend') }}</a>
            <UiProgress
                v-if="pendingSendConfirm"
                class="bi-settings-email__request-pending"
                theme="primary"
                size="sm"
            />
        </div>
    </div>
</template>

<i18n>
    {
        "ru": {
            "resend": "Выслать подтверждение повторно",
            "notconfirmed": "E-mail не подтвержден"
        },
        "en": {
            "resend": "Resend",
            "notconfirmed": "E-mail not confirmed"
        }
    }
</i18n>

<script>
export default {
    name: 'BiSettingsEmail',

    props: {
        email: {
            type: String,
            default: ''
        },

        confirmed: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            pendingSendConfirm: false
        }
    },

    methods: {
        onRequest () {
            this.pendingSendConfirm = true
            this.$emit('request', this.reset)
        },

        reset () {
            this.pendingSendConfirm = false
        }
    }
}
</script>

<style lang="stylus" scoped>
.bi-settings-email
    &__label
        margin-bottom .8rem

    &__request-pending
        margin-left 1.2rem

    &__input
        input()
        width 100%

</style>
