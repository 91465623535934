<template>
    <div
        :id="id"
        :class="cardClasses"
        class="w-bookmakers-card-bonuses__carousel"
    >
        <button
            v-if="hasButton"
            class="w-bookmakers-card-bonuses__button w-bookmakers-card-bonuses__button--prev"
        >
            <IconArrow />
        </button>

        <UiSwiper
            v-if="bonuses.length > 1"
            :options="swiperOptions"
            :slides="bonuses"
            @click-left="onClickLeft"
            @click-right="onClickRight"
        >
            <template #default="{ item, index }">
                <WBookmakersCardBonusesItem
                    :type="item.type"
                    :index="index"
                    :name="item.name"
                    :link="item.link"
                    :amount="item.amount"
                    :currency-code="item.currencyCode"
                    :percent="item.percent"
                    @click-bonus="sendEventBonus(index + 1)"
                />
            </template>
        </UiSwiper>

        <WBookmakersCardBonusesItem
            v-else
            :type="firstBonus.type"
            :index="0"
            :name="firstBonus.name"
            :link="firstBonus.link"
            :amount="firstBonus.amount"
            :currency-code="firstBonus.currencyCode"
            :percent="firstBonus.percent"
            @click-bonus="sendEventBonus(1)"
        />

        <button
            v-if="hasButton"
            class="w-bookmakers-card-bonuses__button w-bookmakers-card-bonuses__button--next"
        >
            <IconArrow />
        </button>
    </div>
</template>

<script>
import IconArrow from '@/assets/svg/arrow.icon.svg'
import WBookmakersCardBonusesItem from '@/components/Widgets/Bookmakers/WBookmakersCardBonusesItem.vue'

export default {
    name: 'WBookmakersCardBonuses',

    components: {
        IconArrow,
        WBookmakersCardBonusesItem
    },

    props: {
        bonuses: {
            type: Array,
            default: () => []
        }
    },

    data () {
        const swiperId = `swiper__${+new Date()}`

        return {
            id: swiperId,
            initialSlide: 0,
            swiperProgress: 0,
            swiperOptions: {
                slidesPerView: 1,
                slidesPerGroup: 1,
                nested: true,
                loop: true,
                navigation: {
                    prevEl: `#${swiperId} .w-bookmakers-card-bonuses__button--prev`,
                    nextEl: `#${swiperId} .w-bookmakers-card-bonuses__button--next`,
                    disabledClass: ''
                }
            }
        }
    },

    computed: {
        hasButton () {
            return this.bonuses.length > 1
        },

        cardClasses () {
            return {
                'w-bookmakers-card-bonuses': true,
                'w-bookmakers-card-bonuses--with-button': this.hasButton
            }
        },

        firstBonus () {
            return this.bonuses[0]
        }
    },

    methods: {
        onClickLeft () {
            this.$emit('scroll-bonus', 'left')
        },

        onClickRight () {
            this.$emit('scroll-bonus', 'right')
        },

        sendEventBonus (position) {
            this.$emit('click-bonus', position)
        }
    }
}
</script>

<style lang="stylus" scoped>
.w-bookmakers-card-bonuses
    display grid
    grid-template-columns 1fr
    grid-gap 1rem
    align-items center

    &--with-button
        margin 0 -1rem
        grid-template-columns min-content minmax(20rem, 1fr) min-content

    &__button
        width 2.4rem
        height @width
        display flex
        justify-content center
        align-items center
        outline none
        background none
        border 0
        padding 0
        color currentColor
        cursor pointer

        &--prev
            transform rotate(180deg)

    &__carousel
        height 5rem
</style>
